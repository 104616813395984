$primary-color: #ffd700; 

@import 'animate.css'; 

@font-face {
  font-family: 'Helvetic Neue';
  src: url('./assets/fonts/helvetica-neu.ttf') format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('./assets/fonts/LaBelleAurore.woff2') format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url('./assets/fonts/CoolveticaRg-Regular.woff2') format('woff2');
}

input,
textarea {
  font-family: 'Helvetica Neue';
}

.bg {
  background-image: url(assets/images/ocean5.png);
  width: 1500px;
  height: 950px;
  background-size: cover;
}

